$(document).on('turbolinks:load', function () {
    $(".filter-tab-search").on('click', function () {
        $(".filter-tab-search a").removeClass("selected");
        $(".all-tab-search").removeClass("selected");

        $(this).find("a").addClass("selected");
        var cosmetic = $(this).data('cosmetic-title')
        $(".content-item").each(function() {
            if ($(this).data("cosmetic") == cosmetic) {
                $(this).parent().show()

            } else {
                $(this).parent().hide()
            }
        })

    });
    $(".all-tab-search").on('click', function () {
        $(".filter-tab-search a").removeClass("selected");
        $(this).addClass("selected");

        $(".item-link-whole").show()
        
    })
});