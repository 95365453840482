
$(document).on('turbolinks:load', function() {
    console.log("here")
    $(".filter-item").on('click', function () {
        console.log("clicked");
        // $('input[name="specialty"]').attr("checked", false);
        id = $(this).data('header-id');
        headerscont = $(".headercont[data-header-id='" + id + "'");
        if( $(this).find("input").prop("checked")) {
            $(this).find("input").prop("checked", false)
            headerscont.hide()
        } else {
            $(this).find("input").prop("checked", true)
            headerscont.show()
        }
    })

    $(".accordian-icon").on('click', function () {
        var parent = $(this).parent().parent();
        if(parent.hasClass("expanded")) {
            parent.find(".accordian-expanded").hide();
            parent.removeClass("expanded")
        } else {
            parent.find(".accordian-expanded").show();
            parent.addClass("expanded")
        }

    });
    $(".expandall").on('click', function () {
        $(".accordian-expanded").show();
        $(".accordian-item").addClass("expanded");
    });
    $(".collapseall").on('click', function () {
        $(".accordian-expanded").hide();
        $(".accordian-item").removeClass("expanded");
    });
});