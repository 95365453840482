$(document).on('turbolinks:load', function () {
    $(".filter-tab a").on('click', function() {
   clickTab($(".filter-tab a").index(this));
   
   
    });

    $(".filter-tab a").on("keyup", function(e) {
        tabKeydown($(".filter-tab a").index(this), e);

    });
    $(".filter-tab a").on('focus', function(e) {
        clickTab($(".filter-tab a").index(this));
     

    });
    var tabKeydown = function(index, e) {
            var $thisTab =  $(".filter-tab a").eq(index);
            var keyCode = e.which,
                $nextTab = $thisTab.parent().next().is('li') ? $thisTab.parent().next().find('a') : false,
                $previousTab = $thisTab.parent().prev().is('li') ? $thisTab.parent().prev().find('a') : false,
                $firstTab = $thisTab.parent().parent().find('li:first').find('a'),
                $lastTab = $thisTab.parent().parent().find('li:last').find('a');


                switch(keyCode) {
                    // Left/Up
                    case 37:
                    case 38:
                        e.preventDefault();
                        e.stopPropagation();
        
                        if (!$previousTab) {
                            $lastTab.focus();
                        } else {
                            $previousTab.focus();
                        }
        
                        break;
        
                    // Right/Down
                    case 39:
                    case 40:
                        e.preventDefault();
                        e.stopPropagation();
        
                        if (!$nextTab) {
                            $firstTab.focus();
                        } else {
                            $nextTab.focus();
                        }
        
                        break;
        
                    // Home
                    case 36:
                        e.preventDefault();
                        e.stopPropagation();
        
                        $firstTab.focus();
        
                        break;
        
                    // End
                    case 35:
                        e.preventDefault();
                        e.stopPropagation();
        
                        $lastTab.focus();
        
                        break;
        
                    // Enter/Space
                    case 13:
                    case 32:
                        e.preventDefault();
                        e.stopPropagation();
        
                        break;
                }

    }

    var clickTab = function(index) {
        
        $(".filter-tab a").attr({
            'tabindex': -1,
            'aria-selected': 'false'
        })
        .removeAttr('aria-describedby')
        .removeClass('selected');
        $(".filter-tab a").eq(index).attr({
        'tabindex': 0,
        'aria-selected': 'true',
        'aria-describedby': 'tab-widget-description'
    }).addClass('selected');
    $(".tabpanel").attr('aria-hidden', 'true').hide();
    $(".tabpanel").eq(index).attr('aria-hidden', 'false').show();
    $(".tab-crumb").remove();
    $('.breadcrumb ol').append('<li class="tab-crumb">'+ $(".filter-tab a").eq(index).html()+'</li>')

   
    }

    window.select_collection = function(collection){
        $(".filter-tab[tab-name='" + collection + "'] > a").trigger("click");
    };

});


// $(document).on('turbolinks:load', function () {
//     $(".filter-tab").on('click', function () {
//         $(".filter-tab a").removeClass("selected");
//         $(".all-tab").removeClass("selected");

//         $(this).find("a").addClass("selected");
//         $(".tab-crumb").remove();
//         $('.breadcrumb ol').append('<li class="tab-crumb">'+ $(this).find(".selected").html()+'</li>')
//         console.log("TEST TEST TEST")
//         var index = $(this).attr('tab-index')
//         $('.landing-collection-list').hide()
//         $(".landing-collection-list[collection-tab='" + index + "']").show()

//     });
//     window.select_collection = function(collection){
//         $(".filter-tab[tab-name='" + collection + "']").trigger("click");
//     };

//     $(".all-tab").on('click', function () {
//         $(".filter-tab a").removeClass("selected");
//         $(this).addClass("selected");
//         $(".tab-crumb").remove();

//         $('.landing-collection-list').hide()
//         $('.landing-collection-list.all-list').show()
        
//     })

//     $(".filter-tab").keydown(function (e) {
//         var key = e.which;
//         if(key == 13)  // the enter key code
//          {
//            $(this).trigger("click");
//            return false;  
//          }
//        }); 
//        $(".all-tab").keydown(function (e) {
//         var key = e.which;
//         if(key == 13)  // the enter key code
//          {
//            $(this).trigger("click");
//            return false;  
//          }
//        });     

// });


(function (document, window, $, undefined) {
    'use strict';

    var $tabWidget = $('.js-tab-widget');

    var setupTabs = function($tab, $allTabs, $tabPanels, $tabListItems, i) {
        $tab
            .attr({
                'id': 'tab-link-' + i,
                'tabindex': '-1',
                'role': 'tab',
                'aria-selected': 'false',
                'aria-controls': 'tab-panel-' + i
            });

        if (i === 0) {
            $tab
                .attr({
                    'tabindex': '0',
                    'aria-selected': 'true',
                    'aria-describedby': 'tab-widget-description'
                })
                .addClass('tab-widget__link--active');
        }

        $tab.on('click', function(e) {
            e.preventDefault();

            tabClick($(this),  $allTabs, $tabPanels, i);
        });
      
        $tab.on('focus', function(e) {
            tabClick($(this),  $allTabs, $tabPanels, i);
        });

        $tab.on('keyup', function (e) {
            tabKeydown($(this), $allTabs, $tabPanels, $tabListItems, i, e);
        });
    };

    var setupTabPanels = function(tabPanel, i) {
        tabPanel
            .attr({
                'id': 'tab-panel-' + i,
                'role': 'tabpanel',
                'aria-hidden': 'true',
                'aria-labelledby': 'tab-link-' + i
            });

        if (i === 0) {
            tabPanel
                .attr('aria-hidden', 'false')
                .addClass('tab-widget__tab-content--active');
        }
    };

    var tabClick = function($thisTab, $allTabs, $tabPanels, i) {
        $allTabs
            .attr({
                'tabindex': -1,
                'aria-selected': 'false'
            })
            .removeAttr('aria-describedby')
            .removeClass('tab-widget__link--active');

        $thisTab
            .attr({
                'tabindex': 0,
                'aria-selected': 'true',
                'aria-describedby': 'tab-widget-description'
            })
            .addClass('tab-widget__link--active');

        $tabPanels
            .attr('aria-hidden', 'true')
            .removeClass('tab-widget__tab-content--active');

        $tabPanels.eq(i)
            .attr('aria-hidden', 'false')
            .addClass('tab-widget__tab-content--active');
    };

    var tabKeydown = function($thisTab, $allTabs, $tabPanels, $tabListItems, i, e) {
        var keyCode = e.which,
            $nextTab = $thisTab.parent().next().is('li') ? $thisTab.parent().next().find('a') : false,
            $previousTab = $thisTab.parent().prev().is('li') ? $thisTab.parent().prev().find('a') : false,
            $firstTab = $thisTab.parent().parent().find('li:first').find('a'),
            $lastTab = $thisTab.parent().parent().find('li:last').find('a');

        switch(keyCode) {
            // Left/Up
            case 37:
            case 38:
                e.preventDefault();
                e.stopPropagation();

                if (!$previousTab) {
                    $lastTab.focus();
                } else {
                    $previousTab.focus();
                }

                break;

            // Right/Down
            case 39:
            case 40:
                e.preventDefault();
                e.stopPropagation();

                if (!$nextTab) {
                    $firstTab.focus();
                } else {
                    $nextTab.focus();
                }

                break;

            // Home
            case 36:
                e.preventDefault();
                e.stopPropagation();

                $firstTab.focus();

                break;

            // End
            case 35:
                e.preventDefault();
                e.stopPropagation();

                $lastTab.focus();

                break;

            // Enter/Space
            case 13:
            case 32:
                e.preventDefault();
                e.stopPropagation();

                break;
        }
    };

    $tabWidget.each(function () {
        var $this = $(this),
            $tabList = $this.find('> ul'),
            $tabListItems = $tabList.find('li'),
            $allTabs = $tabListItems.find('a'),
            $tabPanels = $this.find('> div > div');

        $tabList.attr('role', 'tablist');
        $tabListItems.attr('role', 'presentation');

        $allTabs.each(function (i) {
            setupTabs($(this), $allTabs, $tabPanels, $tabListItems, i);
        });

        $tabPanels.each(function (i) {
            setupTabPanels($(this), i);
        });
    });

  $('html').addClass('js').removeClass('no-js');

})(document, window, jQuery);
