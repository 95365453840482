// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require jquery_ujs
//= require jquery-ui/widgets/sortable
//= require rails_sortable

import "core-js";


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery");
require("custom/search")
require("custom/crumb-handler")
require("custom/header_footer.js")
require("custom/specialty.js")
require("custom/newsletter.js")
require("custom/category_filter.js")
require("custom/search_filter.js")

import ahoy from "ahoy.js";
ahoy.configure({cookies: false}) 
import { ready } from "jquery";


function read_cookie(key)
{
    var result;
    return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null;
}
window.vw = function(v) {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return (v * w) / 100;
}
$( document ).ready(function() {


var isExpanded = false;


$(document).on('turbolinks:click', function (event) {
  if (event.target.getAttribute('href').charAt(0) === '#') {
    return event.preventDefault()
  }
})

$('.dropdown-container').on("click", function() {
  $(this).next().toggle('show');
  $(this).toggleClass('flip-icon');
  isExpanded = true;

  if ($(this).hasClass('flip-icon')){
    $(this).attr("aria-expanded","true");

  } else{
    $(this).attr("aria-expanded","false");

  }


});


});
$(document).on('turbolinks:load', function(){
  $('.popup-container').addClass(' animate__animated animate__zoomIn');
});



$(document).on('turbolinks:load', function(){
  window.scrollTo(0, 0)

  $('.content-container').addClass(' animate__animated animate__zoomIn');


  $('.icon').on('click', function () {
    var buttons = document.getElementsByClassName("mobile-btn");

    if (buttons[1].style.display === "none" || buttons[0].style.display === "" ){
      for (var i = 0; i < buttons.length; i++) {
          buttons[i].style.display = "block";
      }


    } else {

      for (i = 0; i < buttons.length; i++) {
          buttons[i].style.display = "none";
          buttons[0].style.display = "block";
      }


    }
  })
});

$(document).on('turbolinks:load', function () {
      $("#close-cookie").on('click', function () {
        $("#cookie-notification").hide();
        document.cookie = "cookieconfirm=true; expires=Thu, 18 Dec 2025 12:00:00 UTC; path=/";

      })
      if (read_cookie("cookieconfirm")) {
        $("#cookie-notification").hide();
      }
      else {
        $("#cookie-notification").show();

      }




})

var dispatchUnloadEvent = function () {
  var event = document.createEvent("Events")
  event.initEvent("turbolinks:unload", true, false)
  document.dispatchEvent(event)
}
addEventListener("beforeunload", dispatchUnloadEvent)
addEventListener("turbolinks:before-render", dispatchUnloadEvent)






