function read_cookie(key)
{
    var result;
    return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null;
}
$(document).on('turbolinks:load', function() {


    $("#initial-popup button.confirm").on('click', function () {
        document.cookie = "hccookieconfirm=true; expires=Thu, 18 Dec 2025 12:00:00 UTC; path=/";
        $(".popup-wrapper.initial").hide();
      })
      $("#initial-popup button.decline").on('click', function () {
        window.location = "https://sunovion.us";
      })
      if (read_cookie("hccookieconfirm")) {
        $("popup-wrapper.initial").hide();
      }
    $('.search-icon').on('click', function() {
        $('.mobile-search-wrapper').toggle('show');
    
    });
    
    $('.burger-icon').on('click', function() {
      $('.mobile-nav-links').toggle('show');
    
    });
    $(".sharebutton").on('click', function (e) {
      e.preventDefault();
      $(".share-wrapper").show();
      $("#share-popup").show();
    })
    $(".sharesubmit").click(function () {
      return true;    
    });
    $("#share-form").on("ajax:success", function() {
      valid = $(this)[0].checkValidity();
      if (valid) {
        console.log("test")
        $(this).trigger("reset");;
        $(".share-wrapper").hide();
        $("#share-popup").hide();
      }
 
    })
    $(".share-close").on("click", function() {
      $(".share-wrapper").hide();
      $("#share-popup").hide();
    });
})